import { useState, useEffect, useRef, useLayoutEffect } from 'react';

import { fullVersion } from './version';

export const isIEOrWorse = () => /MSIE|Trident/.test(navigator.userAgent);

export const isIE = isIEOrWorse();

export const isChrome = () => /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

export const isFirefox = () => /firefox/.test(navigator.userAgent.toLowerCase());

export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const createDomEvent = (eventName, { bubbles = true, cancelable = true } = {}) => {
  if (typeof Event === 'function') {
    return new window.Event(eventName, { bubbles, cancelable });
  }
  else {
    const event = document.createEvent('Event');
    event.initEvent(eventName, bubbles, cancelable);

    return event;
  }
};

export const useScrollbarWidth = (ref) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const parentEl = ref.current;
    if (!parentEl || !parentEl.hasChildNodes()) return;

    const innerEl = parentEl.firstChild;
    const newWidth = (parentEl.offsetWidth - innerEl?.offsetWidth) || 0;

    setWidth(newWidth);
  }, [ref]);

  return width;
};

export const useScrollbarHeight = (ref) => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const parentEl = ref.current;
    if (!parentEl || !parentEl.hasChildNodes()) return;

    const newHeight = (parentEl.offsetHeight - parentEl.clientHeight) || 0;

    setHeight(newHeight);
  }, [ref]);

  return height;
};

export const useScrollbarCheck = () => {
  const ref = useRef();
  const [hasScroll, setHasScroll] = useState(false);

  useLayoutEffect(() => {
    const resizeListener = () => {
      /** @type HTMLElement */
      const element = ref.current;
      const scrollVisible = element.scrollWidth > element.clientWidth;
      setHasScroll(scrollVisible);
    };

    window.addEventListener('resize', resizeListener);
    resizeListener();

    return () => window.removeEventListener('resize', resizeListener);
  }, []);

  return { ref, hasScroll };
};

export const platformInfo = async () => await import('platform');

export const qaInfo = async () => {
  const platform = await platformInfo();

  const logMethod = (console.table || console.dir).bind(console);

  const generalData = {
    'Date & Time': new Date().toUTCString(),
    'App Version': fullVersion,
    'Browser & OS': platform.description,
    'Window Size': `${window.innerWidth}x${window.innerHeight}`,
    Link: window.location.href,
    Environment: window.location.host,
  };

  logMethod(generalData);
};

export const reloadAllTabs = () => {
  window.localStorage.setItem('RELOAD_TAB', 'RELOAD');
  window.localStorage.removeItem('RELOAD_TAB');
  window.location.reload();
};

export const scrollIntoView = (id, params) =>
  document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'center', ...params });

/**
 * Debug which props have changed or which props trigger an useEffect hook to run
 * Use only for debugging
 * @param {Record<string, any>} props
 */
export const useDebugEffect = (props) => {
  const ref = useRef({});

  useEffect(() => {
    Object.keys(props).forEach(key => {
      console.groupCollapsed(`Prop ${key} changed: ${props[key] !== ref.current[key]}`);
      console.log('previous value: ', ref.current[key]);
      console.log('new value: ', props[key]);
      console.groupEnd();
    });

    ref.current = props;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, Object.values(props));
};

globalThis.__qaInfo = qaInfo;

export const isDev = import.meta.env.DEV;
export const isDSS = globalThis.USE_DSS_API == 'true';

globalThis.APP_VERSION = fullVersion;
